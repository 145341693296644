var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-select', _vm.disabled ? 'disabled' : '', _vm.preserveSearch ? 'preserved' : '', _vm.emptyClass]
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('multiselect', _vm._g(_vm._b({
    ref: "multiSelect",
    attrs: {
      "options": _vm.formattedOptions,
      "value": _vm.optionValue,
      "label": _vm.optionsLabel,
      "track-by": "label",
      "maxHeight": 311,
      "showLabels": false,
      "openDirection": 'below',
      "preselectFirst": _vm.preselectFirst,
      "preserveSearch": _vm.preserveSearch,
      "clearOnSelect": _vm.clearOnSelect,
      "internalSearch": _vm.internalSearch,
      "optionsLimit": _vm.limit,
      "trackBy": _vm.trackBy,
      "show-no-results": false
    },
    on: {
      "input": _vm.handleInput,
      "search-change": _vm.searchChangeAction,
      "blur": function blur($event) {
        return _vm.validation.$touch();
      }
    },
    scopedSlots: _vm._u([_vm.addOptionBtn ? {
      key: "beforeList",
      fn: function fn() {
        return [_c('button', {
          staticClass: "sygni-select__add-option",
          on: {
            "click": _vm.addOption
          }
        }, [_vm._v("Add new counterparty +")])];
      },
      proxy: true
    } : null, {
      key: "option",
      fn: function fn(props) {
        return [_c('user-presentation', {
          attrs: {
            "name": props.option.label,
            "image": props.option.image
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn() {
              return [_c('span', {
                key: _vm.value,
                domProps: {
                  "innerHTML": _vm._s(_vm.highlightedText(props.option.label, props.option.data))
                }
              }), _c('span', {
                staticClass: "text-block description"
              }, [_vm._v(_vm._s(_vm.descriptionString(props.option)))])];
            },
            proxy: true
          }], null, true)
        })];
      }
    }, {
      key: "afterList",
      fn: function fn() {
        return [_c('div', {
          staticClass: "no-option-found"
        }, [_c('p', {
          staticClass: "no-option-found__text"
        }, [_vm._v("Can't find your fund?")]), _c('GenproxButton', {
          attrs: {
            "icon": _vm.PlusCircleIcon
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('registerNewFund');
            }
          }
        }, [_vm._v(_vm._s(_vm.isPersonalAccount ? 'Add new Fund Manager' : 'Add new Fund'))])], 1)];
      },
      proxy: true
    }], null, true)
  }, 'multiselect', Object.assign({}, _vm.removeKey(Object.assign({}, _vm.$props), 'value'), _vm.removeKey(Object.assign({}, _vm.$attrs), 'value')), false), _vm.removeKey(Object.assign({}, _vm.$listeners), 'input'))), _c('sygni-input-error', {
    attrs: {
      "validation": _vm.validation
    }
  }, [_vm._t("error")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }